import React from 'react'
import { useForm, SubmitHandler } from "react-hook-form";
import { Link } from 'react-router-dom';
import APIEndpoints from '../config';
import { useState } from 'react';

interface IFormWelcomeLogin {
    userEmail: string;
    userPass: string;
  }

export function FormForgotPassword(){
    const { register, handleSubmit } = useForm<IFormWelcomeLogin>();
    const [isSubmitting,setIsSubmitting] = useState(false);
    const onSubmit: SubmitHandler<IFormWelcomeLogin> = (e) => {
      setIsSubmitting(true);
      fetch(APIEndpoints.forgotPassword,{
        method:'POST',
        headers: {
        'Accept': 'application/json',
      },})
        .then(response => response.json())
        .then(data => {
          console.log(data);
          setIsSubmitting(false);
        })
        .catch(error => console.error(error));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-floating mb-3">
            <input disabled={isSubmitting} type="email" className="form-control" id="loginUserEmail" placeholder="moj@adres.pl"  {...register("userEmail", { required: true })} />
            <label htmlFor="loginUserEmail">Adres email</label>
          </div>
    
          <div className="d-grid">
            <button className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2" type="submit">
              {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>} Przypomnij hasło
            </button>
            <div className="text-center">
              <Link to={'/'} className="small btn btn-xs">Powrót do logowania</Link> |  <Link to={'/nowe-konto'} className="small btn btn-xs">Nie masz konta?</Link>
            </div>
          </div>
    
        </form>
      );
}