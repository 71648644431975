import * as React from "react";
import { WelcomeContainer } from "../Componets/WelcomeContainer";
import { WelcomeHeading } from "../Componets/WelcomeHeading";
import { FormForgotPassword } from "../Componets/FormForgotPassword.tsx";
import { useEffect } from 'react';

export default function ForgotPasswordPage(){
    useEffect(() => {
        document.title = 'Zapomniałem hasła - ZdajLEKko.pl';
      },[]);
    return <>
    <WelcomeContainer>
        <WelcomeHeading txtHeading="ZdajLEKko.pl" txtSubHeading="W celu przypomnienia hasła, proszę podaj adres email." />
        <FormForgotPassword />
    </WelcomeContainer>
    </>;
}