import FlashMessages from "./FlashMessages.tsx";

export function WelcomeHeading({txtHeading='ZdajLEKko!',txtSubHeading=''}){
    return (
      <>
        <h3 className="login-heading mb-2">{txtHeading}</h3>
        {txtSubHeading?<h2 className='h6 mb-4'>{txtSubHeading}</h2>:''}
        <FlashMessages />
      </>
    );
}