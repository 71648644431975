import Alert from 'react-bootstrap/Alert';
import { useContext, useEffect } from "react";
import {GlobalContext} from './GlobalContext.tsx';
import APIEndpoints from '../config';

function FlashMessages() {
    const {globalState,globalDispatch} = useContext(GlobalContext);
//    const [messagesLoaded,setMessagesLoaded] = useState(0);

    useEffect( () => { 
      fetch(APIEndpoints.messages,{
        method:'GET',mode:'cors',
        headers: {
          'Accept': 'application/json',
          "X-Requested-With": "XMLHttpRequest",
        }}).then(response => response.json())
          .then(data => {
            if (data.messages!==undefined)
              globalDispatch({type:'SET_MESSAGES',flashMessages:data.messages});
          })
          .catch(error => console.error(error));
    },[] );

    return (
        <div id="flash-messages">
          {globalState.flashMessages.map((message) => (
            <Alert onClose={() => globalDispatch({type:'REMOVE_MESSAGE',removeTime:message.time})}
                   key={message.type+message.time} 
                   variant={message.type} 
                   dismissible>
                    <p className="m-0">{message.text}</p>
            </Alert>
          ))}
        </div>
      );
}

export default FlashMessages;