//import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import * as React from "react";
import { BrowserRouter as Router,  Routes,  Route, Outlet } from "react-router-dom";
import IndexPage from './Pages/index';
import RegisterPage from './Pages/register';
import ForgotPasswordPage from './Pages/forgot';
import { useState, createContext, useContext, useReducer } from "react";
import { GlobalContext, GlobalReducer, GlobalReducerInitialState } from './Componets/GlobalContext.tsx';

//window.fMessages = [{time:123,type:'ok',text:'dupa'}];

function App() {
  const [state, dispatch] = useReducer(GlobalReducer,GlobalReducerInitialState)
/*  const wMessages = window.fMessages;

  if (window.fMessages.length){
    dispatch({type:'SET_MESSAGES',flashMessages:wMessages});
    window.fMessages=[];
  }*/

  return (
    <GlobalContext.Provider value={{globalState:state,globalDispatch:dispatch}}>
      <Router basename="/" element={<Outlet />}>
        <Routes>
          <Route exact index={true} path="/" element={<IndexPage />} />
          <Route exact index={false} path="/nowe-konto" element={<RegisterPage />} />
          <Route exact index={false} path="/nowe-haslo" element={<ForgotPasswordPage />} />
        </Routes>
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;