import * as React from "react";
import { WelcomeContainer } from "../Componets/WelcomeContainer";
import { WelcomeHeading } from "../Componets/WelcomeHeading";
import { FormRegister } from "../Componets/FormRegister.tsx";
import { useEffect } from 'react';

export default function RegisterPage(){
    useEffect(() => {
        document.title = 'Nowe konto - ZdajLEKko.pl';
      }, []);
    return <>
    <WelcomeContainer>
        <WelcomeHeading txtHeading="ZdajLEKko.pl" txtSubHeading="Proszę wypełnić wszystkie pola w celu założenia konta." />
        <FormRegister />
    </WelcomeContainer>
    </>;
}