import { createContext } from "react";



export const GlobalReducerInitialState = {
    flashMessages : []
}


export const GlobalContext = createContext(GlobalReducerInitialState);
export default GlobalContext;
 
export const GlobalReducer = (state, action) => {
    if (action.type==='ADD_MESSAGES'&&state.flashMessages.length===0)
        action.type='SET_MESSAGES';
    switch(action.type) {
       case 'CLEAR_MESSAGES':
        return {...state,flashMessages:[]};
       case 'SET_MESSAGES':
        return {...state, flashMessages : [ ...action.flashMessages ] }
       case 'ADD_MESSAGES':
        return {...state, flashMessages : [...state.flashMessages, ...action.flashMessages]}
       case 'REMOVE_MESSAGE':
        return {...state, flashMessages : state.flashMessages.filter(i => i.time !== action.removeTime ) }
      default:
        return state
    }
}