  export function WelcomeContainer({children,backgroundImageUrl='https://source.unsplash.com/WEQbe2jBg40/600x1200'}){
    return (
      <div className="container-fluid ps-md-0">
        <div className="row g-0">
          <div className="d-none d-md-flex col-md-4 col-lg-6 bg-image" style={{backgroundImage: `url('${backgroundImageUrl}')`}}></div>
          <div className="col-md-8 col-lg-6">
            <div className="full-height d-flex align-items-center py-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 col-lg-8 mx-auto">
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }