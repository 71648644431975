import * as React from "react";
//import { Link } from 'react-router-dom';
import { WelcomeContainer } from "../Componets/WelcomeContainer";
import { WelcomeHeading } from "../Componets/WelcomeHeading";
import { FormLogin } from "../Componets/FormLogin.tsx"
import { useEffect } from 'react';



export default function IndexPage(){
    useEffect(() => {
        document.title = 'ZdajLEKko.pl';
      }, []);
    return <>
    <WelcomeContainer>
        <WelcomeHeading txtHeading="ZdajLEKko.pl" txtSubHeading="Jeśli posiadasz konto w serwisie i chcesz kontynuować naukę to możesz się zalogować. " />
        <FormLogin />
    </WelcomeContainer>
    </>;
}